'use strict';

class SettingsController {
  constructor($scope, $http, Auth, $rootScope, $state) {
    this.errors = {};
    this.submitted = false;
    this.settingsSubmitted = false;
    this.$scope = $scope;
    this.$http = $http;
    this.Auth = Auth;
    this.product = $rootScope.product;
    this.state = $state;

    this.allergenStyles = [
      { style: 'bold', label: 'Grassetto' },
      { style: 'bold-underlined', label: 'Grassetto e sottolineato' }
    ];

    this.userId = Auth.getCurrentUser()._id;
    this.userSettings = {
      _id: this.userId,
      link: '',
      logo: {
        id: 'logo-img',
        label: 'Imagine logo',
        value: '',
        userId: this.userId,
        preview: true
      },
      noExternalIngredientsInSearch: false,
      allergenStyle: 'bold',
      eWeightSymbol: false,
      colNutritionalVals: false,
      manufacturer: '',
      packager: '',
      manufacturingPlant: '',
      countryOfOrigin: '',
      favoriteIngredients: [],
      new: true
    };

    $http.get('/api/user-settings/' + this.userId).then((res) => {
      this.userSettings = res.data;
    });

    /**************************************************************************/

    this.$scope.flowUploadStarted = function($flow, model) {
      progressJs('#' + model.id + ' .upload-progress').start().autoIncrease(2, 500);
      model.state = 'started';
      model.value = $flow.files[0].name;
    };

    this.$scope.flowFileProgress = function($flow, model) {
      if($flow.files[0]) {
        progressJs('#' + model.id + ' .upload-progress').set($flow.files[0].progress() * 100);
      }
    };

    this.$scope.flowUploadComplete = function($flow, model) {
      progressJs('#' + model.id + ' .upload-progress').end();
      if($flow.files[0]) {
        model.state = 'completed';
        model.uniqueIdentifier = $flow.files[0].uniqueIdentifier;
      } else {
        $scope.cancelUpload($flow, model);
      }
    };

    this.$scope.flowUploadFile = function($flow) {
      $flow.upload();
    };

    this.$scope.cancelUpload = function($flow, model) {
      progressJs('#' + model.id + ' .upload-progress').end();
      if($flow.files[0]) { $flow.files[0].cancel(); }
      model.value = '';
      model.state = '';
      model.uniqueIdentifier = '';
      // TODO: cancel upload?
    };

    this.$scope.deleteUploaded = function($flow, model) {
      if($flow.files[0]) { $flow.files[0].cancel(); }
      // $http.get('/api/filetransfers/delete/' + model.uniqueIdentifier);
      model.value = '';
      model.state = '';
      model.uniqueIdentifier = '';
      // TODO: fix for when uploading same file in two different places
      // TODO: possibile fix, rename every uploaded file/somehow make them unique...
      // TODO: see: https://github.com/flowjs/ng-flow/issues/145 can pass a custom func to generate unique Id
    };

    /**************************************************************************/

  }

  changeSettings() {
    this.settingsSubmitted = true;

    if(this.userSettings.new) {
      this.$http.post('/api/user-settings/', this.userSettings).then(() => {
        alert('Impostazioni salvate');
      }).catch(() => {
        alert('Errore durante il salvataggio delle impostazioni. Riprovare tra poco');
      });
    } else {
      this.$http.put('/api/user-settings/' + this.userId, this.userSettings).then(() => {
        alert('Impostazioni salvate');
      }).catch(() => {
        alert('Errore durante il salvataggio delle impostazioni. Riprovare tra poco');
      });
    }
  }

  changePassword(form) {
    this.submitted = true;

    if(form.$valid) {
      this.Auth.changePassword(this.user.oldPassword, this.user.newPassword)
        .then(() => {
          this.message = 'Password successfully changed.';
        })
        .catch(() => {
          form.password.$setValidity('mongoose', false);
          this.errors.other = 'Incorrect password';
          this.message = '';
        });
    }
  }

  gotoProduct() {
    this.state.go('product', { user: this.product.user, id: this.product.id });
  }

}

angular.module('appApp')
  .controller('SettingsController', SettingsController);
